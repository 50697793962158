export default function VectorBBOPLogo({ className }: { className?: string }) {
  return (
    <svg
      width="176"
      height="56"
      viewBox="0 0 176 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.11716 0.632202C11.8474 0.632202 13.8495 2.0346 13.8495 5.52534C13.8495 7.80696 13.1362 8.93469 11.6285 9.75869C13.2464 10.3909 14.3163 11.5462 14.3163 14.1301C14.3163 17.9783 11.9851 19.4636 8.33752 19.4636H0.987274V0.632202H8.11716ZM4.03176 3.27133V8.66002H8.06352C9.9279 8.66002 10.7514 7.72557 10.7514 5.88284C10.7514 4.0401 9.81917 3.27133 7.95334 3.27133H4.03176ZM4.03176 11.2439V16.8244H8.17225C10.0642 16.8244 11.1892 16.2199 11.1892 13.9659C11.1892 11.7119 9.59881 11.2439 8.11716 11.2439H4.03031H4.03176Z"
        fill="#00D4FE"
      />
      <path d="M17.5782 0H20.5676V19.4635H17.5782V0Z" fill="#00D4FE" />
      <path
        d="M35.6233 5.71863V19.465H32.6615V18.6134C32.6615 18.6134 30.6594 19.7673 28.9588 19.7673C25.0372 19.7673 24.2138 17.7327 24.2138 12.8948V5.72008H27.2031V12.9224C27.2031 15.9743 27.3945 17.1006 29.5344 17.1006C31.1798 17.1006 32.66 16.3856 32.66 16.3856V5.71863H35.6233Z"
        fill="#00D4FE"
      />
      <path
        d="M49.7729 16.9072L49.828 19.1336C49.828 19.1336 46.7023 19.7658 44.2885 19.7658C40.1741 19.7658 38.5837 17.567 38.5837 12.7276C38.5837 7.88825 40.7496 5.41479 44.5349 5.41479C48.3202 5.41479 50.2934 7.42175 50.2934 11.7103L50.102 13.8539H41.6006C41.6282 16.0527 42.4502 17.1528 44.7278 17.1528C47.0053 17.1528 49.7743 16.9057 49.7743 16.9057L49.7729 16.9072ZM47.3605 11.5461C47.3605 8.79654 46.5371 7.8897 44.5364 7.8897C42.5357 7.8897 41.6021 8.90698 41.5745 11.5461H47.3605Z"
        fill="#00D4FE"
      />
      <path
        d="M66.693 0.632202C70.4232 0.632202 72.4253 2.0346 72.4253 5.52534C72.4253 7.80696 71.712 8.93469 70.2043 9.75869C71.8222 10.3909 72.8921 11.5462 72.8921 14.1301C72.8921 17.9783 70.5609 19.4636 66.9133 19.4636H59.5631V0.632202H66.693ZM62.6061 3.27133V8.66002H66.6379C68.5023 8.66002 69.3257 7.72557 69.3257 5.88284C69.3257 4.0401 68.3935 3.27133 66.5277 3.27133H62.6061ZM62.6061 11.2439V16.8244H66.7466C68.6385 16.8244 69.7635 16.2199 69.7635 13.9659C69.7635 11.7119 68.1732 11.2439 66.6915 11.2439H62.6047H62.6061Z"
        fill="#00D4FE"
      />
      <path
        d="M75.9888 5.71854H78.9506V7.36799C78.9506 7.36799 81.2818 5.8566 83.613 5.41626V8.41289C81.118 8.90699 78.9782 9.86906 78.9782 9.86906V19.4635H75.9888V5.71854Z"
        fill="#00D4FE"
      />
      <path
        d="M96.036 16.2198C96.0911 17.0453 96.4477 17.3737 97.2422 17.4841L97.1595 19.7658C95.4865 19.7658 94.5268 19.5463 93.512 18.7485C93.512 18.7485 91.3185 19.7658 89.096 19.7658C86.3806 19.7658 85.0092 18.2544 85.0092 15.3943C85.0092 12.5343 86.5995 11.4909 89.5338 11.2438L93.0712 10.9416V9.95188C93.0712 8.44049 92.413 7.97254 91.0691 7.97254C89.2323 7.97254 85.9138 8.2472 85.9138 8.2472L85.8036 6.1298C85.8036 6.1298 88.793 5.41479 91.3156 5.41479C94.6616 5.41479 96.0331 6.81719 96.0331 9.95043V16.2183L96.036 16.2198ZM89.8919 13.3612C88.6306 13.4717 88 14.0762 88 15.313C88 16.5497 88.5205 17.3199 89.673 17.3199C91.2358 17.3199 93.0741 16.7154 93.0741 16.7154V13.059L89.8934 13.3612H89.8919Z"
        fill="#00D4FE"
      />
      <path
        d="M99.7923 0.219482H102.782V3.38178H99.7923V0.219482ZM99.7923 5.71863H102.782V19.465H99.7923V5.71863Z"
        fill="#00D4FE"
      />
      <path
        d="M106.4 5.71854H109.362V6.57015C109.362 6.57015 111.364 5.41626 113.147 5.41626C116.959 5.41626 117.892 7.58743 117.892 12.0969V19.4649H114.931V12.1797C114.931 9.43017 114.574 8.083 112.489 8.083C110.899 8.083 109.39 8.798 109.39 8.798V19.4649H106.4V5.71854Z"
        fill="#00D4FE"
      />
      <path
        d="M0 41.4296C0 40.0185 0.237759 38.7222 0.713278 37.5392C1.1888 36.3577 1.85568 35.3361 2.71539 34.4743C3.57509 33.6125 4.59427 32.944 5.77292 32.4673C6.95156 31.9907 8.24474 31.7523 9.6539 31.7523C11.0631 31.7523 12.3229 31.9907 13.4929 32.4673C14.6628 32.944 15.6776 33.6125 16.5373 34.4743C17.397 35.3361 18.0639 36.3577 18.5394 37.5392C19.015 38.7207 19.2527 40.0185 19.2527 41.4296C19.2527 42.8407 19.015 44.1094 18.5394 45.2924C18.0639 46.4739 17.397 47.5013 16.5373 48.3718C15.6776 49.2423 14.6628 49.9166 13.4929 50.3919C12.3229 50.8685 11.0428 51.1069 9.6539 51.1069C8.26504 51.1069 6.95301 50.8685 5.77292 50.3919C4.59427 49.9152 3.57364 49.2423 2.71539 48.3718C1.85568 47.5013 1.1888 46.4753 0.713278 45.2924C0.237759 44.1109 0 42.8233 0 41.4296ZM3.67512 41.4296C3.67512 42.6387 3.93028 43.7069 4.44349 44.6326C4.95526 45.5583 5.66418 46.2777 6.56883 46.7907C7.47348 47.3037 8.5028 47.5609 9.6539 47.5609C10.805 47.5609 11.8242 47.3037 12.7114 46.7907C13.5972 46.2777 14.2975 45.5583 14.8092 44.6326C15.321 43.7083 15.5776 42.6402 15.5776 41.4296C15.5776 40.219 15.321 39.1247 14.8092 38.199C14.2975 37.2733 13.5972 36.5539 12.7114 36.0409C11.8242 35.5279 10.805 35.2707 9.6539 35.2707C8.5028 35.2707 7.47348 35.5323 6.56883 36.054C5.66418 36.5771 4.95526 37.2965 4.44349 38.2121C3.93173 39.1291 3.67512 40.2016 3.67512 41.4281V41.4296Z"
        fill="white"
      />
      <path
        d="M21.4447 56.0001V37.3344H24.1326L25.0372 40.5229H24.7632V47.2573H25.0372V55.9986H21.4447V56.0001ZM28.6297 51.107C27.4409 51.107 26.4087 50.8279 25.5316 50.2684C24.6545 49.7089 23.9731 48.9082 23.4889 47.8633C23.0047 46.8184 22.7626 45.554 22.7626 44.0703C22.7626 42.5865 23.009 41.3759 23.5034 40.3325C23.9963 39.2876 24.6864 38.4767 25.5736 37.8997C26.4594 37.3228 27.4888 37.0336 28.6587 37.0336C29.8287 37.0336 30.958 37.3315 31.8815 37.9273C32.805 38.5232 33.527 39.3472 34.0475 40.4008C34.5679 41.4544 34.8289 42.678 34.8289 44.0703C34.8289 45.4625 34.5679 46.6643 34.0475 47.7267C33.527 48.7905 32.7992 49.6188 31.867 50.2147C30.9348 50.8105 29.8562 51.1084 28.6312 51.1084L28.6297 51.107ZM28.0266 47.7804C28.94 47.7804 29.6953 47.4375 30.2897 46.7501C30.8841 46.0627 31.1813 45.1689 31.1813 44.0703C31.1813 42.9716 30.8841 42.0735 30.2897 41.3759C29.6953 40.6798 28.9414 40.331 28.0266 40.331C27.1118 40.331 26.3536 40.674 25.7505 41.3614C25.1474 42.0488 24.8459 42.9513 24.8459 44.0688C24.8459 45.1864 25.1474 46.0612 25.7505 46.7486C26.3536 47.436 27.1118 47.779 28.0266 47.779V47.7804Z"
        fill="white"
      />
      <path
        d="M42.9721 51.0517C41.6369 51.0517 40.4669 50.7625 39.4623 50.1856C38.4561 49.6086 37.6805 48.7977 37.1311 47.7528C36.5831 46.7079 36.3091 45.4814 36.3091 44.0688C36.3091 42.6562 36.5831 41.4529 37.1311 40.3993C37.6791 39.3457 38.4561 38.5202 39.4623 37.9259C40.4669 37.33 41.6383 37.0321 42.9721 37.0321C44.3059 37.0321 45.499 37.3344 46.4965 37.9389C47.4924 38.5435 48.2652 39.3733 48.8132 40.4269C49.3612 41.4805 49.6352 42.6853 49.6352 44.0412C49.6352 44.2432 49.6308 44.4394 49.6221 44.6327C49.612 44.8245 49.5902 44.9858 49.554 45.1137H39.2434V42.4193H46.8401L46.292 43.7113C46.292 42.5748 46.0224 41.6491 45.4831 40.9341C44.9438 40.2191 44.1073 39.8616 42.9736 39.8616C41.9863 39.8616 41.1918 40.1682 40.5873 40.783C39.9842 41.3977 39.6826 42.2173 39.6826 43.2433V44.6995C39.6826 45.7807 39.9885 46.628 40.6018 47.2427C41.2136 47.8575 42.0501 48.1641 43.1113 48.1641C44.0435 48.1641 44.7742 47.9723 45.3048 47.5871C45.8354 47.202 46.2921 46.7254 46.6762 46.1571L49.1713 47.5871C48.5682 48.7047 47.7491 49.5621 46.7168 50.158C45.6832 50.7538 44.4364 51.0517 42.9736 51.0517H42.9721Z"
        fill="white"
      />
      <path
        d="M59.8922 37.0321C60.8055 37.0321 61.542 37.1876 62.1002 37.5001C62.6583 37.8125 63.0773 38.2339 63.3614 38.7644C63.6441 39.2963 63.8326 39.8965 63.9239 40.565C64.0153 41.2349 64.0617 41.9252 64.0617 42.6402V50.7771H60.4692V42.6126C60.4692 41.7872 60.3083 41.2378 59.9893 40.9632C59.6689 40.6885 59.263 40.5504 58.7686 40.5504C58.312 40.5504 57.8451 40.6376 57.3696 40.812C56.8941 40.9864 56.4505 41.2335 56.0402 41.5547C55.6285 41.8758 55.2762 42.2377 54.9848 42.6402L54.5179 40.7713H55.4226V50.7771H51.8301V37.3344H54.5179L55.2588 39.9183L54.1077 39.8631C54.5832 39.3137 55.1268 38.8283 55.7386 38.4054C56.3504 37.984 57.0043 37.6497 57.6987 37.4027C58.3931 37.1556 59.1253 37.0321 59.8922 37.0321Z"
        fill="white"
      />
      <path
        d="M80.815 41.8699C81.8748 41.8699 82.7157 41.5952 83.3376 41.0445C83.9595 40.4951 84.2698 39.7162 84.2698 38.7076C84.2698 37.6453 83.9639 36.8431 83.3506 36.3025C82.7374 35.7618 81.9009 35.4915 80.8411 35.4915H77.2762L78.0445 34.6952V50.777H74.4521V32.056H81.1702C82.5417 32.056 83.729 32.3365 84.7352 32.8946C85.7398 33.4541 86.527 34.2272 87.0939 35.2169C87.6608 36.2065 87.9435 37.3706 87.9435 38.7076C87.9435 40.0446 87.6593 41.1869 87.0939 42.1853C86.527 43.1851 85.7369 43.9582 84.7221 44.5076C83.7073 45.0569 82.5228 45.333 81.1702 45.333H77.3037V41.8699H80.815Z"
        fill="white"
      />
      <path
        d="M93.1568 50.9137C92.5537 50.9137 91.9999 50.7858 91.4982 50.5286C90.9952 50.2713 90.5936 49.8731 90.292 49.3325C89.9905 48.7919 89.8397 48.0914 89.8397 47.2296V32.0547H93.4047V45.9653C93.4047 46.6077 93.5322 47.0233 93.7888 47.2166C94.044 47.4084 94.3557 47.5043 94.721 47.5043V50.7204C94.5195 50.793 94.2774 50.8439 93.9947 50.8715C93.7106 50.8991 93.4322 50.9122 93.1582 50.9122L93.1568 50.9137Z"
        fill="white"
      />
      <path
        d="M101.164 51.1607C100.176 51.1607 99.3081 50.9732 98.5586 50.5968C97.809 50.2219 97.232 49.69 96.8305 49.0026C96.4274 48.3152 96.2274 47.5145 96.2274 46.5975C96.2274 45.6805 96.4419 44.8841 96.8725 44.2054C97.3016 43.5267 97.8917 43.0006 98.6412 42.6242C99.3907 42.2493 100.24 42.0604 101.191 42.0604C102.489 42.0604 103.544 42.3583 104.359 42.9541C105.172 43.55 105.726 44.3972 106.018 45.4973L104.345 45.2779V42.0342C104.345 41.5212 104.162 41.0634 103.797 40.6594C103.431 40.2569 102.837 40.0549 102.013 40.0549C101.502 40.0549 100.935 40.1145 100.313 40.2336C99.6908 40.3528 99.0413 40.5766 98.3657 40.9065L97.2958 38.2674C98.0816 37.8823 98.9181 37.58 99.8053 37.3605C100.691 37.1411 101.583 37.0306 102.479 37.0306C103.704 37.0306 104.719 37.2501 105.523 37.6904C106.328 38.1308 106.931 38.7353 107.332 39.5041C107.734 40.2743 107.936 41.1535 107.936 42.1432V50.7756H105.084L104.316 48.2193L106.016 47.7252C105.704 48.8064 105.133 49.6493 104.302 50.2539C103.47 50.8584 102.424 51.1607 101.162 51.1607H101.164ZM102.151 48.4126C102.809 48.4126 103.34 48.2484 103.741 47.9185C104.143 47.5886 104.345 47.1482 104.345 46.5989C104.345 46.0496 104.143 45.586 103.741 45.2663C103.338 44.9466 102.809 44.7852 102.151 44.7852C101.493 44.7852 100.962 44.9466 100.561 45.2663C100.158 45.5874 99.9576 46.0321 99.9576 46.5989C99.9576 47.1657 100.159 47.5886 100.561 47.9185C100.962 48.2484 101.493 48.4126 102.151 48.4126Z"
        fill="white"
      />
      <path
        d="M109.967 40.6594V37.3329H118.578V40.6594H109.967ZM116.492 51.024C115.121 51.024 114.038 50.6622 113.243 49.9384C112.447 49.2147 112.05 48.055 112.05 46.4608V37.4985L111.995 37.3343L112.736 34.1735H115.642V45.8839C115.642 46.471 115.783 46.8968 116.067 47.1627C116.35 47.4287 116.711 47.5609 117.15 47.5609C117.443 47.5609 117.708 47.5377 117.946 47.4926C118.184 47.4476 118.394 47.3967 118.577 47.3415V50.6956C118.284 50.806 117.974 50.8874 117.645 50.9427C117.315 50.9979 116.931 51.0255 116.492 51.0255V51.024Z"
        fill="white"
      />
      <path
        d="M128.394 40.9355H120.469V37.6366H128.394V40.9355ZM122.361 50.777V36.3171C122.361 35.3274 122.539 34.4888 122.896 33.8015C123.252 33.1141 123.749 32.5967 124.39 32.2479C125.03 31.9006 125.789 31.7262 126.666 31.7262C127.106 31.7262 127.499 31.7538 127.845 31.809C128.193 31.8643 128.476 31.9195 128.695 31.9747V35.2736C128.512 35.2373 128.325 35.2009 128.132 35.1632C127.941 35.1268 127.716 35.1079 127.461 35.1079C127.004 35.1079 126.639 35.2402 126.363 35.5061C126.089 35.7721 125.952 36.2066 125.952 36.8112V50.7756H122.359L122.361 50.777Z"
        fill="white"
      />
      <path
        d="M136.648 51.107C135.259 51.107 134.038 50.809 132.987 50.2132C131.936 49.6174 131.117 48.7934 130.533 47.7397C129.947 46.6861 129.656 45.4625 129.656 44.0703C129.656 42.678 129.947 41.4544 130.533 40.4008C131.117 39.3472 131.936 38.5217 132.987 37.9273C134.038 37.3315 135.259 37.0336 136.648 37.0336C138.037 37.0336 139.257 37.3315 140.309 37.9273C141.36 38.5232 142.177 39.3472 142.763 40.4008C143.347 41.4544 143.64 42.678 143.64 44.0703C143.64 45.4625 143.347 46.6861 142.763 47.7397C142.177 48.7934 141.36 49.6188 140.309 50.2132C139.257 50.809 138.037 51.107 136.648 51.107ZM136.648 47.6148C137.635 47.6148 138.44 47.2892 139.062 46.6382C139.682 45.9886 139.994 45.1311 139.994 44.0674C139.994 43.0036 139.682 42.1432 139.062 41.4835C138.44 40.8237 137.635 40.4938 136.648 40.4938C135.661 40.4938 134.86 40.8193 134.249 41.4704C133.635 42.1214 133.329 42.9789 133.329 44.0412C133.329 45.1035 133.635 45.9653 134.249 46.6251C134.86 47.2849 135.661 47.6148 136.648 47.6148Z"
        fill="white"
      />
      <path
        d="M148.604 37.3344L149.509 41.046V50.7771H145.916V37.3344H148.604ZM148.769 42.8873L148.111 42.585V39.8354L148.358 39.5332C148.559 39.2033 148.865 38.8458 149.277 38.4607C149.688 38.0755 150.164 37.7413 150.703 37.4579C151.243 37.1745 151.805 37.0321 152.389 37.0321C152.681 37.0321 152.946 37.051 153.185 37.0873C153.423 37.1251 153.606 37.1891 153.733 37.2792V40.5504H152.856C151.667 40.5504 150.754 40.7379 150.113 41.1143C149.472 41.4907 149.026 42.0807 148.769 42.8873Z"
        fill="white"
      />
      <path
        d="M155.789 50.7771V37.3344H158.477L159.218 39.9183L158.067 39.8631C158.542 39.3137 159.063 38.8283 159.629 38.4054C160.196 37.984 160.812 37.6497 161.481 37.4027C162.148 37.1556 162.846 37.0321 163.579 37.0321C164.492 37.0321 165.224 37.1876 165.772 37.5001C166.32 37.8125 166.727 38.2339 166.993 38.7644C167.258 39.2963 167.441 39.8965 167.541 40.565C167.641 41.2349 167.691 41.9252 167.691 42.6402V50.7771H164.099V42.6126C164.099 41.7872 163.934 41.2378 163.606 40.9632C163.277 40.6885 162.884 40.5504 162.427 40.5504C161.769 40.5504 161.128 40.7481 160.508 41.1419C159.886 41.5358 159.366 42.0357 158.945 42.6402L158.478 40.7713H159.383V50.7771H155.789ZM172.38 42.6126C172.38 41.7872 172.219 41.2378 171.9 40.9632C171.58 40.6885 171.191 40.5504 170.735 40.5504C170.076 40.5504 169.436 40.7481 168.815 41.1419C168.193 41.5358 167.673 42.0357 167.252 42.6402L166.348 39.8645C166.823 39.3152 167.348 38.8298 167.925 38.4069C168.5 37.9854 169.122 37.6512 169.789 37.4041C170.456 37.1571 171.156 37.0336 171.887 37.0336C172.783 37.0336 173.505 37.1891 174.053 37.5015C174.601 37.814 175.013 38.2354 175.287 38.7658C175.561 39.2977 175.748 39.8979 175.849 40.5664C175.949 41.2364 176 41.9267 176 42.6417V50.7785H172.38V42.6126Z"
        fill="white"
      />
    </svg>
  );
}
